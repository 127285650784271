<template>
  <BasePage :isLoaded="!!Products">
    <template v-slot:content>
      <v-container id="js-products-row">
        <v-row align="center" class="our-creations-row">
          <v-col cols="12" sm="8" offset-sm="2" md="4" offset-md="2">
            <h2 class="h2 -sci-gradient">Catalogue</h2>
          </v-col>
          <v-col cols="12" sm="8" offset-sm="2" md="3" offset-md="1">
            <ButtonFilters
              @on-filter-click="filterProducts"
              :selectedFilter="categoryTitleFilter"
            />
            <SearchBar :search.sync="search" />
          </v-col>
        </v-row>
      </v-container>
      <CardList
        :cards="currentList"
        @on-reset-filters="filterProducts"
        :filterTitle="categoryTitleFilter"
        :search="search"
      />
      <v-container>
        <v-row align="center" class="buttons-row">
          <v-col
            v-if="currentList.length"
            cols="3"
            offset-sm="1"
          >
            <ButtonScrollTo target="js-products-row" />
          </v-col>
          <v-col cols="6" sm="4">
            <ButtonLoadMore
              v-if="currentPage < pages"
              label="Load more"
              @click="loadMoreData"
            />
          </v-col>
        </v-row>
      </v-container>
    </template>
  </BasePage>
</template>

<script>
import { uniqBy } from 'lodash'
import BasePage from '@/organisms/BasePage'
import ListProductQuery from '@/graphql/ListProduct.gql'
import CardList from '@/molecules/CardList.vue'
import ButtonScrollTo from '@/atoms/ButtonScrollTo.vue'
import ButtonLoadMore from '@/atoms/ButtonLoadMore.vue'
import ButtonFilters from '@/atoms/ButtonFilters.vue'
import SearchBar from '@/molecules/SearchBar.vue'
export default {
  name: 'ProductShowcasePage',
  components: {
    BasePage,
    CardList,
    ButtonScrollTo,
    ButtonLoadMore,
    ButtonFilters,
    SearchBar
  },
  apollo: {
    Products: {
      query: ListProductQuery,
      async result (res) {
        this.allProducts = res.data.Products.items.filter(product => !!product)
        this.allProducts = uniqBy(this.allProducts, 'id')
      }
    }
  },
  data: () => ({
    search: '',
    categoryTitleFilter: '',
    categoryIdFilter: -1,
    allProducts: [],
    currentPage: 1,
    limit: 6
  }),
  computed: {
    pages () {
      return Math.ceil(this.filteredList.length / this.limit)
    },
    filteredList () {
      let products = this.allProducts

      if (this.categoryIdFilter !== -1) {
        products = products.filter(product => product.Category.id === this.categoryIdFilter)
      }

      if (this.search) {
        products = products.filter(product => product.title.toLowerCase().indexOf(this.search.toLowerCase()) !== -1)
      }

      return products
    },
    currentList () {
      return this.filteredList.slice(0, Math.min(this.limit * this.currentPage, this.filteredList.length))
    }
  },
  methods: {
    filterProducts (category) {
      if (category) {
        this.categoryIdFilter = category.id
        this.categoryTitleFilter = category.title
      } else {
        this.categoryIdFilter = -1
        this.categoryTitleFilter = 'All'
        this.search = ''
      }
      this.currentPage = 1
    },
    loadMoreData () {
      this.currentPage = Math.min(this.pages, (this.currentPage + 1))
    }
  },
  metaInfo () {
    return {
      title: 'Catalogue',
      meta: [
        { vmid: 'ogtitle', property: 'og:title', itemprop: 'name', content: 'Catalogue' },
        { vmid: 'description', name: 'description', content: 'Regi catalogue' },
        { vmid: 'ogdescription', property: 'og:description', content: 'Regi catalogue' }
      ]
    }
  }
}
</script>

<style scoped lang="scss">
.products-showcase-container {
  position: relative;
}

.fullpage-row {
  height: calc(100vh - 81px);
  position: relative;

  h1 {
    @include breakpoint ('sm-and-down') {
      padding-bottom: rem(32px);
    }
  }

  p {
    @include breakpoint ('sm-and-down') {
      padding-bottom: rem(125px);
    }
  }

  .invite-to-scroll {
    position: absolute;
    bottom: rem(10px);
    right: 50%;
    transform: translateX(50%);

    @include breakpoint ('sm-and-down') {
      bottom: rem(78px);
    }
  }
}

.our-creations-row,
.top-collections-row {
  padding-top: rem(100px);
}

.buttons-row {
  padding: rem(100px) 0;
}
</style>
