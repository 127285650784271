<template>
  <div class="d-flex align-center justify-space-between">
    <v-text-field
      v-model="currentSearch"
      @input="onInput"
      placeholder="Search products"
    />
    <v-fade-transition mode="out-in">
      <SearchSvg v-if="show" />
    </v-fade-transition>
  </div>
</template>

<script>
import { debounce } from 'lodash'
import SearchSvg from '@/assets/img/search.svg'

export default {
  name: 'SearchBar',
  props: {
    search: {
      type: String
    }
  },
  components: { SearchSvg },
  created () {
    this.onInput = debounce(this.onInput, 400)

    this.$watch('search', (value) => {
      this.show = !value
      this.currentSearch = value
    })
  },
  data () {
    return {
      currentSearch: '',
      show: true
    }
  },
  methods: {
    onInput () {
      this.$emit('update:search', this.currentSearch)
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .v-input {
  margin: 0;
  padding: 0;
  margin-right: rem(12px);
  max-height: rem(42px);
}

svg {
  width: rem(22px);
  height: rem(22px);

  path {
    fill: #999;
  }
}
</style>
